









import { Vue, Component, Prop } from "vue-property-decorator";
import PsychologistProfile from "../../../components/PsychologistProfile.vue";
import userModel from "@/models/user.model";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

@Component({
  components: {
    PsychologistProfile,
    ClipLoader,
  },
})
export default class MyProfile extends Vue {
  psychologist: any = null;

  loading = true;

  @Prop()
  updated: any;


  mounted() {
    this.loadPsychologist();
  }

  async loadPsychologist() {
    this.loading = true;
    try {
      this.psychologist = await userModel.getProfile();
    } catch (error) {
      console.error(error);
      this.$vs.notification({
        title: "Erro",
        text: error.message,
        color: "#FF6767",
      });
    }
    this.loading = false
  }
}
